<template>
    <div>
        <b-card class="m-0" no-body>
            <div slot="header">
                <h3>{{translate('welcome_subscriptions')}}</h3>
            </div>
            <search-wrapper-card :active="welcome_subscription_active" :activeStatus="activeStatus" :inactive="welcome_subscription_inactive" :inactiveStatus="inactiveStatus" form_type="welcomeSubscriptions">
                <q-input slot="search_input" bottom-slots @input="isTyping = true" v-model="searchQuery" id="filternameWelcomeSubscriptions" class="search-input" type="text" :placeholder="translate('find_welcome_subscription')" :dense="true">
                    <template v-slot:prepend>
                        <q-icon name="search" />
                    </template>
                </q-input>
                <template slot="add_button">
                    <q-btn no-caps v-if="!$q.screen.lt.sm" color="primary" @click.prevent="showAddWelcomeSubscriptionPanel">
                        <i class="fa fa-plus mr-1"></i> {{ translate('add_welcome_subscription') }}
                    </q-btn>
                    <q-btn dense round v-if="$q.screen.lt.sm" class="bg-white text-primary" icon="add" @click.prevent="showAddWelcomeSubscriptionPanel" />
                </template>
            </search-wrapper-card>
            
        </b-card>
        
        <div class="list_wrapper-welcomeSubscriptions">
            <q-item 
                v-for="(welcomeSubscription, i) in welcomeSubscriptions" 
                :key="'ws-item-' + i + '-' + (i.id,welcomeSubscription.id)" 
                :id="welcomeSubscription.id" 
                class="list-item full-width draggable-item" 
                :class="welcomeSubscription.is_active ? 'ws-active' : 'ws-inactive'"
                v-if="welcome_subscription_active === 'active' && welcomeSubscription.is_active || welcome_subscription_inactive === 'inactive' && !welcomeSubscription.is_active ">
                    <q-item-section class="p-0 pl-md-2" side>
                    <div class="image-thumbnail cursor-pointer" @click.prevent="showViewWelcomeSubscriptionPanel(welcomeSubscription.id, welcomeSubscription.subscription_name)">
                        <img v-if="welcomeSubscription.main_image_link" :src="welcomeSubscription.main_image_link" />
                        <i v-else class="fa fa-photo fa-2x"></i>
                    </div>
                </q-item-section>
                    <q-item-section class="full-height align-self-center pt-2 pb-2">
                        <strong class="text-muted ml-3">{{ welcomeSubscription.name }} </strong>
                    </q-item-section>
                    <!-- [+] Desktop sections. -->
                    <template v-if="!$q.screen.lt.sm">
                        <q-item-section side class="full-height align-self-center pt-2 pb-2">
                            <q-btn round icon="delete" color="danger" @click="showDeleteWelcomeSubscriptionPanel(welcomeSubscription.id)">
                                <q-tooltip :offset="[10, 10]">{{ translate('delete_' + entity_type) }}</q-tooltip>
                            </q-btn>
                        </q-item-section>
                        <q-item-section side class="full-height align-self-center pt-2 pb-2">
                            <toggle-active 
                                :is_active="welcomeSubscription.is_active" 
                                :no_label="true" 
                                :patch_toggle_route="patch_toggle_route"
                                :item_id="welcomeSubscription.id" 
                                :entity_type="entity_type" />
                        </q-item-section>
                    </template>
                    <!-- [-] Desktop sections. -->
            </q-item>
        </div>
        <infinite-loading :identifier="'WelcomeSubscriptions-'+infWelcomeSubscriptions" slot="append" @infinite="getWelcomeSubscriptions" />
    </div>
</template>

<script>
    import _ from 'lodash'
    import { eventBus } from '../../../main'
    import SearchWrapperCard from '../../template-parts/SearchWrapperCard'
    import addWelcomeSubscription from './forms/AddWelcomeSubscription'
    import ViewWelcomeSubscription from './forms/ViewWelcomeSubscription'
    import DeleteWelcomeSubscription from './forms/DeleteWelcomeSubscription'
    import ToggleActive from '../../template-parts/ToggleActive'

    export default {
        name: 'WelcomeSubscriptionsWorker',
        components: {
            'search-wrapper-card': SearchWrapperCard,
            'toggle-active': ToggleActive,
        },
        data: function () {
            return {
                infWelcomeSubscriptions: +new Date(),
                message: '',
                welcomeSubscriptions: [],
                isTyping: false,
                welcome_subscription_active: 'active',
                welcome_subscription_inactive: '',
                searchQuery: "",
                order_by: '',
                show: true,
                page: 1,
                enableButon: false,
                state: {}
            }
        },
        mounted: function () {
            eventBus.$on('toggle_active_welcome_subscription', (item) => {
                this.toggleWelcomeSubscription(item)
            })
            eventBus.$on('delete_welcome_subscription', (id) => {
                this.deleteWelcomeSubscription(id)
            })
        },
        computed: {
            entity_type: function () {
                return 'welcome_subscription'
            },
            patch_toggle_route: function () {
                return baseUrl + 'welcome_subscriptions'
            },
        },
        watch: {
            searchQuery: _.debounce(function () {
                this.isTyping = false
            }, 200),
            isTyping: function (value) {
                if (!value) {
                    this.resetMainList()
                }
            }
        },
        methods: {
            toggleWelcomeSubscription: function (item) {
                if (item.id !== null) {
                    // Find index of specific object using findIndex method,
                    var objIndex = this.welcomeSubscriptions.findIndex((obj => obj.id === item.id))
                    if (this.welcomeSubscriptions[objIndex]) {
                        // And update is_active for the given item in list.
                        this.welcomeSubscriptions[objIndex]['is_active'] = item.is_active ? true : false
                        eventBus.$emit('update_toggle_' + this.entity_type, item)

                        if (item.is_active === 0 && this.welcome_subscription_active === 'active' && this.welcome_subscription_inactive === '') {
                            this.deleteWelcomeSubscription(item.id)
                        }
                        if (item.is_active === 1 && this.welcome_subscription_active === '' && this.welcome_subscription_inactive === 'inactive') {
                            this.deleteWelcomeSubscription(item.id)
                        }
                    }
                }
            },
            deleteWelcomeSubscription: function (id) {
                // Find index of specific object using findIndex method,
                var objIndex = this.welcomeSubscriptions.findIndex((obj => obj.id === id))
                // and remove the element from the welcomeSubscriptions array.
                this.welcomeSubscriptions.splice(objIndex, 1)
            },
            resetMainList: function () {
                this.page = 1
                this.welcomeSubscriptions = []
                this.infWelcomeSubscriptions++
            },
            activeStatus: _.debounce(function () {
                if (this.welcome_subscription_active === 'active') {
                    this.welcome_subscription_active = ''
                } else {
                    this.welcome_subscription_active = 'active'
                }
                this.resetMainList()
            }, 200),
            inactiveStatus: _.debounce(function () {
                if (this.welcome_subscription_inactive === 'inactive') {
                    this.welcome_subscription_inactive = ''
                } else {
                    this.welcome_subscription_inactive = 'inactive'
                }
                this.resetMainList()
            }, 200),
            getWelcomeSubscriptionsUrl: function () {
                var welcome_subscriptionsUrl = baseUrl +
                    'welcome_subscriptions?page=' + this.page +
                    '&items_per_page=' + this.$items_per_page +
                    (this.searchQuery ? '&search=' + this.searchQuery : '')
                return welcome_subscriptionsUrl
            },
            getWelcomeSubscriptions: function ($state) {
                var url = this.getWelcomeSubscriptionsUrl();

                var headers = {
                    'Authorization': 'Bearer ' + this.accessToken(),
                }
                this.state = $state
                // To solve latency with server, push the items into a temporary scoped array and then into the main array.
                let welcomeSubscriptions = this.welcomeSubscriptions
                axios.get( url, {
                        headers: headers
                    })
                    .then(response => {
                        $state = this.state
                        if (response.data.items && response.data.items.length > 0) {
                            welcomeSubscriptions.push(...response.data.items)
                        } 
                        if (response.data.next_page === true) {
                            this.page++
                            $state.loaded()
                        } else {
                            // To solve latency with server, push the items into a temporary scoped array and then into the main array.
                            this.welcomeSubscriptions = welcomeSubscriptions
                            $state.complete()
                        }
                    })
                    .catch(function (error) {
                        // console.log(error)
                    })
            },
            showDeleteWelcomeSubscriptionPanel: function (id) {
                const panelInstance = this.$showPanel({
                    component: DeleteWelcomeSubscription,
                    props: {
                        welcomeSubscription_id: id
                    }
                })
            },
            showAddWelcomeSubscriptionPanel: function () {
                const panelInstance = this.$showPanel({
                    component: addWelcomeSubscription,
                })

                panelInstance.promise.then(result => {
                    if (result && result.status) {
                        this.resetMainList()
                    }
                })
            },
            showViewWelcomeSubscriptionPanel: function (id, subscription_name) {
                const panelInstance = this.$showPanel({
                    component: ViewWelcomeSubscription,
                    props: { id:id, subscription_name:subscription_name }
                })

                panelInstance.promise
                    .then(result => {
                        if (result && result.status) {
                        }
                    })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .search-input {
        margin-left: -15px !important;
    }
    .q-item.list-item {
        background: white;
        border-width: 1px;
        border-style: solid;
        border-color: rgba(0, 0, 0, .16);
        margin: 0 0 .5rem;
        padding-left: 0;
        padding-top: 0;
        padding-bottom: 0;
        transition: border-color .1s ease-in-out;

        &:first-child {
            margin-top: .5rem;
        }

        &:hover {
            border-color: rgba(0, 0, 0, .26);
        }
    }

    .ws-active {
        border-left: 2px solid var(--q-color-primary)!important;
    }
    .ws-inactive {
        border-left: 2px solid var(--q-color-dark)!important;
    }
</style>
