<template>
    <div class="animated fadeIn">
        <welcome-subscriptions-worker v-if="showWelcomeSubscriptionsWorker" :baseUrl="baseUrl"></welcome-subscriptions-worker>
    </div>
</template>

<script>
    import _ from 'lodash'
    import WelcomeSubscriptionsWorker from './WelcomeSubscriptionsWorker'
    export default {
        name: 'WelcomeSubscriptions',
        components: {
            'welcome-subscriptions-worker': WelcomeSubscriptionsWorker
        },
        data: function () {
            return {
                baseUrl: baseUrl,
                enableCard: false,
            }
        },
        computed: {
            showWelcomeSubscriptionsWorker: function () {
                if (this.$appSettings.currentUserType() == 'admin') {
                    return !this.enableCard;
                } else if (this.$appSettings.currentUserType() == 'worker') {
                    return !this.enableCard;
                } else if (this.$appSettings.currentUserType() == 'customer') {
                    return this.enableCard;
                }
            }
        },
    }
</script>
