<template>
    <div class="fit sticky-form-actions">
        <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
        <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
            <b-tab class="p-0">
                <template slot="title">
                    <q-icon name="add" class="mr-1" /><strong>{{ translate('welcome_subscription') }}</strong>
                </template>
                <b-list-group class="list-group-accent">
                    <b-list-group-item >
                        <div class="full-width row mb-3 text-muted align-items-center">
                            <div class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-file-signature fa-2x"></i></div>
                            <div class="col-10 col-md info-card">
                                <small>{{ translate('name') }}</small><br />
                                <strong>{{welcomeSubscription.name}}</strong>
                            </div>
                            <div class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-info fa-2x"></i></div>
                            <div class="col-10 col-md info-card">
                                <small>{{ translate('description') }}</small><br />
                                <strong>{{ welcomeSubscription.description}}</strong>
                            </div>
                        </div>
                        <div class="full-width row mb-3 text-muted align-items-center">
                            <div class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-bullseye fa-2x text-primary"></i></div>
                            <div class="col-10 col-md info-card">
                                <small>{{ translate('subscription') }}</small><br />
                                <strong class="text-primary">{{subscription_name}}</strong>
                            </div>
                            <div class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-sort-amount-desc fa-2x"></i></div>
                            <div class="col-10 col-md info-card">
                                <small>{{ translate('max_usage') }}</small><br />
                                <strong>{{ welcomeSubscription.max_usage}}</strong>
                            </div>
                        </div>
                        <div class="full-width row mb-3 text-muted align-items-center">
                            <div class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-calendar fa-2x"></i></div>
                            <div class="col-10 col-md info-card">
                                <small>{{ translate('valid_from') }}</small><br />
                                <strong>{{ prettyDate(welcomeSubscription.valid_from) }}</strong>
                            </div>
                            <div class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-calendar fa-2x"></i></div>
                            <div class="col-10 col-md info-card">
                                <small>{{ translate('valid_until') }}</small><br />
                                <strong>{{ prettyDate(welcomeSubscription.valid_until) }}</strong>
                            </div>
                        </div>
                        <div class="full-width row mb-3 text-muted align-items-center">
                            <div class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-users fa-2x"></i></div>
                            <div class="col-10 col-md info-card">
                                <small>{{ translate('total_usage') }}</small><br />
                                <strong>{{ welcomeSubscription.total_usage }}</strong>
                            </div>
                        </div>
                    </b-list-group-item>
                    <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                        <q-item class="p-3">
                            <q-item-section side>
                                <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                    <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('close') }}
                                </q-btn>
                            </q-item-section>
                        </q-item>
                    </div>
                </b-list-group>
                
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
    import _ from 'lodash'
    import ToggleActive from '../../../template-parts/ToggleActive'

    export default {
        name: 'ViewWelcomeSubscription',
        props: ['id', 'subscription_name'],
        components: {
            'toggle-active': ToggleActive,
        },
        data: function () {
            return {
                formActionsRowHeight: 0,
                slideoutTabsWidth: 0,

                welcomeSubscription: {},
                msg: null,

            }
        },
        created: function () {
            this.getWelcomeSubscription()
            this.getElementsWidthHeight()
        },
        beforeDestroy: function () {
            window.removeEventListener('resize', this.getElementsWidthHeight)
        },
        mounted: function () {
            window.addEventListener('resize', this.getElementsWidthHeight)
        },
        methods: {
            getElementsWidthHeight: _.debounce(function () {
                this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
                this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
            }, 200),
            getWelcomeSubscription: function () {
                var headers = {
                    'Authorization': 'Bearer ' + this.accessToken(),
                    'Content-Type': 'application/json'
                }
                axios.get(baseUrl + 'welcome_subscriptions/' + this.id, {
                        headers: headers
                    })
                    .then(response => {
                        this.welcomeSubscription = response.data.item
                    })
                    .catch(function (error) {
                        // console.log(error)
                    })
            },
            closePanel: function () {
                this.$emit('closePanel')
            }
        }
    }
</script>
